import React from "react";
import { graphql } from "gatsby";
import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";

const Team = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { modules, seo } = page || {};

  return (
    <Layout>
      <PageMeta {...seo} />
      {modules && <ModuleZone {...modules} />}
    </Layout>
  );
};

export default Team;

export const pageQuery = graphql`
  query sanityTeamHomeQuery {
    page: sanityTeamHome {
      seo {
        ...SEO
      }
      modules {
        ...TeamModules
      }
    }
  }
`;
